import ALL_EMPLOYEES from '@/graphql/employees/query/getAllEmployeesAutocomplete.gql'

const employees = ref<{label: string; value: string}[]>([])
const key = ref(0)
const firstTime = ref(true)

export async function employeesAutocomplete(params: any) {
  const apolloQuery = ALL_EMPLOYEES
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'UUID',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchEmployeesCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await employeesAutocomplete({ search: queryString })
    cb(results?.getAllEmployees?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchEmployeesCb')
  }
}

export function getSearchEmployees(entityEmployee: any, entityEmployees: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchEmployees('', entityEmployee, entityEmployees)
  }

  return employees?.value.filter((i: any) => i.label) ?? []
}

export async function searchEmployees(queryString: any, entityEmployee: any, entityEmployees: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await employeesAutocomplete({ search: queryString })
    const remoteEmployees = results?.getAllEmployees?.data?.map((i: any) => {
      return {
        label: `${i.user?.first_name ?? ''} ${i.user?.last_name ?? ''}`,
        value: i.uuid
      }
    }) ?? []
    const employeesToAdd: { label: any; value: any }[] = []
    entityEmployees?.forEach((j: any) => {
      if (!remoteEmployees.find((k: any) => k.value === j.uuid)) {
        employeesToAdd.push({
          label: `${j.user?.first_name ?? ''} ${j.user?.last_name ?? ''}`,
          value: j.uuid
        })
      }
    })
    if (entityEmployee && !remoteEmployees.find((k: any) => k.value === entityEmployee.uuid)) {
      employeesToAdd.push({
        label: `${entityEmployee.user?.first_name ?? ''} ${entityEmployee.user?.last_name ?? ''}`,
        value: entityEmployee.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    employees.value = [...remoteEmployees, ...employeesToAdd]

    firstTime.value = false

    if (entityEmployee) {
      getSearchInvoices(entityEmployee, entityEmployee)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchEmployees')
  }
}

export function getEmployeeKey() {
  return key.value
}

export function initialEmployees() {
  employees.value = []
  key.value = 0
  firstTime.value = true
}